export enum FollowUpActionStatusType {
	FRESH = "fresh",
	MODIFICATION_SELECTED = "modification-selected",
	TRANSITION_SELECTED = "transition-selected",
	EXTRAS_SELECTED = "extras-selected",
	FEATURES_SELECTED = "features-selected",
	SHOPPING_CART_CONFIGURED = "shopping-cart-configured",
	SHOPPING_CART_SAVED = "shopping-cart-saved",
	ORDER_DATA_SAVED = "order-data-saved",
	UNBUNDLE_STEP = "unbundle-step",
	DELETE_STEP = "delete-step",
	COMPLETED = "completed"
}

export const BUNDLE_DISABLED_STATUS: FollowUpActionStatusType[] = [
	FollowUpActionStatusType.UNBUNDLE_STEP,
	FollowUpActionStatusType.DELETE_STEP,
	FollowUpActionStatusType.COMPLETED
];

export const PROMOTION_DISABLED_STATUS: FollowUpActionStatusType[] = [...BUNDLE_DISABLED_STATUS, FollowUpActionStatusType.FRESH];

export const CONFIGURATION_DISABLED_STATUS: FollowUpActionStatusType[] = [
	...PROMOTION_DISABLED_STATUS,
	FollowUpActionStatusType.MODIFICATION_SELECTED,
	FollowUpActionStatusType.TRANSITION_SELECTED,
	FollowUpActionStatusType.EXTRAS_SELECTED
];

export const ORDER_DATA_DISABLED_STATUS: FollowUpActionStatusType[] = [
	...CONFIGURATION_DISABLED_STATUS,
	FollowUpActionStatusType.FEATURES_SELECTED,
	FollowUpActionStatusType.SHOPPING_CART_SAVED
];
